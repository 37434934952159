import { RouteComponentProps } from "@reach/router";
import * as dates from "./dates.json";
import SnowStorm from "react-snowstorm";

export interface DayProps extends RouteComponentProps {
  id?: string;
}

export function getData(id: string) {
  const today = Number(new Date().toISOString().slice(8, 10));
  let data: any;
  if (Number(id) <= today) {
    data = dates["ids"].filter((d) => {
      return d.day === Number(id);
    });
  } else {
    data = dates["ids"].filter((d) => d.id === id);
  }
  return data.length > 0 ? data[0] : { id: "home", text: "Home", day: "Hello" };
}

const randomColor = () => {
  const color = `rgb(
      ${Math.floor(Math.random() * 175)},
      ${Math.floor(Math.random() * 175)},
      ${Math.floor(Math.random() * 175)})`;
  return color;
};

const BasicMessage = ({ color, goToToday }) => {
  return (
    <>
      <h1 id="word">Pupa! Buon mese dell'avvento</h1>
      <h3 id="meaning">
        Ogni giorno riceverai un id da usare per leggere il messaggio del
        giorno. Aggiungilo alla fine con "/id"
      </h3>
      <button
        onClick={goToToday}
        style={{ backgroundColor: color }}
        className="newword"
      >
        Oggi
      </button>
    </>
  );
};
const AdventDay = (props: DayProps) => {
  const data = getData(props.id as string);
  const color = data.id !== "home" ? randomColor() : "#C30F16";
  const html = document.documentElement;
  html.style.backgroundColor = color;
  const today = Number(new Date().toISOString().slice(8, 10));
  const goToToday = () => {
    window.location.href =
      props.location.protocol + "//" + props.location.host + "/" + today;
  };
  return (
    <>
      <div>
        <SnowStorm excludeMobile={false} />
      </div>
      <div className="container">
        <div style={{ backgroundColor: "white" }} className="wordbox">
          <div className="fadeIn" key={Math.random()} style={{ color }}>
            {data.id !== "home" ? (
              <>
                <h1 id="word">🎄 {data.day} Dicembre</h1>
                <h3 id="meaning">{data.text}</h3>
                {data.day !== today ? (
                  <button
                    onClick={goToToday}
                    style={{ backgroundColor: color }}
                    className="newword"
                  >
                    Oggi
                  </button>
                ) : null}
              </>
            ) : (
              <BasicMessage color={color} goToToday={goToToday} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdventDay;
